export const locales = {
  tests: {
    render: 'Translated', // used for tests
    render_params: 'Translated {{value}}', // used for tests
  },

  __value__: '{{value}}',

  header: {
    logo: 'NoRamp',

    options: {
      login: 'Log in',
      signup: 'Sign up',

      logout: 'Logout',

      notifications: 'Notifications',
      account: 'Account',
      settings: 'Settings',
    },
  },

  footer: {
    terms: 'Terms and conditions',
    privacy: 'Privacy policy',
  },

  pages: {
    // /404
    not_found: {
      title: '404 - Page not found',
      msg: 'The page you were looking for is not found.',
      button: 'Back to home',
    },

    // /not-access
    not_access: {
      title: 'Login required',
      msg: 'Please login to access to this page.',
      button: 'Login',
    },

    // /login
    login: {
      title: 'Login',

      error: 'An error occurred while doing login',

      email: {
        title: 'Login with Email',

        email_description: 'We will send you a login link to your email',
        email_field: 'Email',
        email_placeholder: 'Enter your email',
        email_button: 'Login / Signup',
        email_confirmation: 'Login link sent, please check your email.',
      },
    },

    // /notifications
    notifications: {
      title: 'Notifications',
      popup_label: 'Notifications',
      popup_view_more_label: 'View all notifications',
      no_notifications: "You're up to date!",
    },
  },

  auth: {
    enter: 'Enter',
    authenticating: 'Authenticating...',
    continue_with: 'Continue with',
    email_button: 'Continue with Email',
    google_button: 'Continue with Google',
    github_button: 'Continue with Github',
    all_sign_in_options: 'All sign in options',

    modal: {
      neutral: {
        title: 'Sign in to continue',
      },
    },
  },

  form: {
    changes_saved: 'Changes saved',
    changes_added: 'Added',
    changes_removed: 'Removed',

    select: 'Select',
    button_save: 'Save',
    button_cancel: 'Cancel',
    button_add: 'Add',
    button_create: 'Create',
    button_edit: 'Edit',
    button_delete: 'Delete',
    button_remove: 'Remove',
    confirm_remove: 'Confirm remove',

    errors: {
      required: '{{label}} is required',
      min: '{{label}} must be greater than {{value}}',
      max: '{{label}} must be less than {{value}}',
      minLength: '{{label}} is too short (min {{value}} characters)',
      maxLength: '{{label}} is too long (max {{value}} characters)',
      pattern: '{{label}} pattern error',
      validate: '{{label}} validation error',

      validate_inline: 'Validation error',
    },
  },

  misc: {
    link_copied: 'Link copied!',
  },

  errors: {
    try_again_button: 'Try again',
    unknown: 'An error occurred.',
    error_400: 'Request error.',
    error_404: 'Resource not found.',
    error_429: 'Too many requests, try again later.',
    error_401: "You don't have access to this resource.",
    error_403: 'Error processing your request.',
    error_500: 'Error processing your request.',

    // login
    'auth/cancelled-popup-request': 'Login cancelled',
    'auth/popup-closed-by-user': 'Login cancelled',
    'auth/account-disabled': 'This account account is disabled',

    // generic
    'file/invalid-mime-type': 'Unsupported file selected',

    // /user/me
    'user/username-in-use': 'Username already in use',
    'user/invalid-username': 'Invalid username',
    'user/email-in-use': 'Email already in use',
    'user/invalid-email': 'Enter a valid email',
    'user/require-email': 'Add your email',

    isEmail: 'Enter a valid email',
    arrayMaxSize: 'Maximum number of elements reached.',
    isUrl: 'Invalid url',
    isPhoneNumber: 'Phone must be a valid phone number',
    max: 'Code must not be greater than 100000',
    isNumberString: 'Invalid number',
    isEthereumAddress: 'Invalid Address',

    // firebase
    'auth/invalid-action-code': 'Invalid login link',
    'auth/invalid-email':
      'The email provided does not match the sign-in email address.',
    'auth/email-already-in-use':
      'The email provided is already in use by an existing user.',
    // apps
    'app/max-apps-reached': 'Max amount of apps reached',

    // card
    'card/declined': 'Card declined',

    // kyc
    'kyc/account-creation-failed': 'KYC initialization failed',
    'kyc/not-found': 'KYC for seller is incomplete',

    // payments
    'payment/price-already-used': 'Payment form expired, refresh this page',
    'payment/app-is-not-active': 'This App is not active yet',
    'payment/cannot-execute-trigger':
      "We can't process your payment right now, try again later",

    'payment/cannot-save-card':
      'This Payment Method was previously used and may not be used again.',

    // near
    'near/cannot-parse-key': 'Invalid Phrase or Key',
    'near/near-account-not-found': 'NEAR Account not found on Network',
    'near/contract-not-found': 'NEAR Contract not found on Network',
    'near/invalid-attach-deposit_amount': 'Max attached deposit is 0.1 NEAR',
    'near/creating-account':
      'An error occurred creating your Near Managed Wallet',

    // evm
    'evm/cannot-parse-key': 'Invalid Private Key',
    'evm/contract-not-found': 'Contract not found on Network',
    'evm/creating-account':
      'An error occurred creating your EVM Managed Wallet',

    // solana
    'solana/cannot-parse-key': 'Invalid Private Key',
    'solana/contract-not-found': 'Contract not found on Network',
    'solana/creating-account': 'An error occurred creating Solana Wallet',

    // wallet
    'wallet/in-use-by-trigger': 'Wallet in use by one or more triggers',
    'wallet/network-not-active': 'Network is disabled at the moment, try later',
    'wallet/mainnet-not-allowed': 'Mainnet networks are not allowed on Testnet',
    'wallet/testnet-not-allowed': 'Testnet networks are not allowed on Mainnet',
    'wallet/cannot-use-liquidity':
      'Managed Wallets is not supported by this Network at the moment',
    'wallet/managed-wallets-cannot-be-deleted':
      'Deleting Managed Wallets is not supported at the moment.',
    'wallet/max-managed-wallets-per-app-reached':
      'Max Managed Wallets per app reached',

    // members
    'member/already-invited': 'Member already invited',
    'member/already-member': "You're already a member of this app",
    'member/require-email':
      'You must set your email on your account before accepting the invite',
    'member/max-members-reached': 'Max members reached for this app',
  },

  common: {
    dialog_confirm: 'Ok',
    loading: 'Loading',
    load_more: 'Load more',
    see_more: 'See more',
    see_less: 'See less',
    no_content: 'No content found',
  },
};

export default locales;
