import * as React from 'react';

import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import {ImSpinner8} from 'react-icons/im';

import {cn} from 'lib/utils';

import {useForm} from 'components/Form';

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded text-base font-semibold ring-offset-background transition-colors duration-200 ease-in-out focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-foreground focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        solid: 'text-white', // default
        soft: 'bg-opacity-10 hover:bg-opacity-20',
        ghost: 'bg-opacity-0 hover:bg-opacity-10',
        outline: 'bg-opacity-0 border hover:bg-opacity-10',
      },
      size: {
        sm: 'h-8 py-2 px-2 text-sm',
        md: 'h-9 py-2 px-2', // default
        icon: 'h-10 w-10',
      },

      color: {
        primary: 'bg-blue-600 hover:bg-blue-700', // default
        success: 'bg-green-500 hover:bg-green-600',
        danger: 'bg-red-500 hover:bg-red-600',
        warning: 'bg-yellow-500 hover:bg-yellow-600',
        noramp: 'bg-snow-50 dark:bg-nr-gray-950',
      },
    },
    compoundVariants: [
      // ghost
      {
        variant: ['ghost'],
        color: ['primary'],
        className: 'text-blue-700',
      },
      {
        variant: ['ghost'],
        color: ['success'],
        className: 'text-green-500',
      },
      {
        variant: ['ghost'],
        color: ['danger'],
        className: 'text-red-600',
      },
      {
        variant: ['ghost'],
        color: ['warning'],
        className: 'text-yellow-600',
      },

      // soft
      {
        variant: ['soft'],
        color: ['primary'],
        className: 'text-blue-700',
      },
      {
        variant: ['soft'],
        color: ['success'],
        className: 'text-green-500',
      },
      {
        variant: ['soft'],
        color: ['danger'],
        className: 'text-red-600',
      },
      {
        variant: ['soft'],
        color: ['warning'],
        className: 'text-yellow-600',
      },

      // outline
      {
        variant: ['outline'],
        color: ['primary'],
        className: 'border-blue-400 dark:border-blue-900 text-blue-700',
      },
      {
        variant: ['outline'],
        color: ['success'],
        className: 'border-green-400 dark:border-green-900 text-green-500',
      },
      {
        variant: ['outline'],
        color: ['danger'],
        className: 'border-red-400 dark:border-red-900 text-red-600',
      },
      {
        variant: ['outline'],
        color: ['warning'],
        className: 'border-yellow-400 dark:border-yellow-900 text-yellow-600 ',
      },
    ],
    defaultVariants: {
      variant: 'solid',
      size: 'md',
      color: 'primary',
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      color,
      asChild = false,
      disabled,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const {isSubmitting} = useForm();

    const isSubmitButton = props.type === 'submit';

    const showLoading = isSubmitButton && isSubmitting;

    const shouldBeDisabled = showLoading || disabled;

    return (
      <Comp
        className={cn(buttonVariants({variant, size, color, className}))}
        disabled={shouldBeDisabled}
        ref={ref}
        {...props}>
        {showLoading && (
          <div className="flex items-center justify-center h-screen mr-2">
            <ImSpinner8 className=" animate-spin" />
          </div>
        )}
        {children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export const RoundedCategoryBtn = ({
  active,
  text,
  onClick,
}: {
  active?: boolean;
  text: string;
  onClick?: () => any;
}) => {
  return (
    <span
      onClick={() => onClick?.()}
      className={`cursor-pointer my-auto flex p-[8px_10px] justify-center items-center gap-[10px] rounded-[6px] ${
        active
          ? 'bg-[#F0F1F3] text-black dark:bg-[#3e3e3e] dark:text-white'
          : 'text-[#BEBEBE]'
      } text-[12px] font-bold`}>
      {text}
    </span>
  );
};

export {Button, buttonVariants};
