enum API {
  TIME = 'time',

  AUTH = 'auth',
  AUTH_LINK = 'auth/link',

  SHOPPERS_AUTH = 'shoppers/auth',

  STATS_DASHBOARD = 'stats/dashboard',

  STATS_SYSTEM = 'stats/system',

  USERS = 'users',
  USERS_ME = 'users/me',
  USERS_ITEM = 'users/:user_id',
  USERS_LATEST = 'users/latest',
  USERS_EXPORT = 'users/export',
  USERS_PROFILE = 'users/:user_id/profile',

  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_SEEN = 'notifications/seen',
  NOTIFICATIONS_STATUS = 'notifications/status',

  API_KEYS = 'api-keys',

  COUNTRIES = 'countries',

  KYCS = 'kycs',
  KYCS_OUTDATED = 'kycs/outdated',

  KYCS_MANAGE = 'kycs/manage/:edit_token',
  KYCS_MANAGE_CREATE = 'kycs/manage/:edit_token/create',
  KYCS_MANAGE_LINK = 'kycs/manage/:edit_token/link',
  KYCS_MANAGE_SYNC = 'kycs/manage/:edit_token/sync',

  PROVIDERS = 'providers',

  APPS = 'apps',
  APPS_ALL = 'apps/all',
  APPS_ITEM = 'apps/:app_id',
  APPS_STATS = 'apps/:app_id/stats',

  APPS_KYCS_ME = 'apps/:app_id/kycs/me',
  APPS_KYCS_ME_LINK = 'apps/:app_id/kycs/me/link',
  APPS_KYCS_ME_SYNC = 'apps/:app_id/kycs/me/sync',

  APPS_KYCS = 'apps/:app_id/kycs',
  APPS_KYCS_ITEM = 'apps/:app_id/kycs/:kyc_id',
  APPS_KYCS_REFRESH_TOKEN = 'apps/:app_id/kycs/:kyc_id/refresh-token',
  CUSTOM_RECEIPTS = 'apps/:app_id/custom-receipts',

  PAYMENTS = 'payments',
  PAYMENTS_APP = 'payments/:app_id',
  PAYMENTS_ITEM = 'payments/:app_id/:payment_id',
  PAYMENTS_RECEIPT = 'payments/:app_id/:payment_id/receipt',
  PAYMENTS_TOKEN = 'payments/:token_id/token',
  PAYMENTS_TOKEN_RECEIPT = 'payments/:token_id/token/receipt',

  PAYMENT_INTENTS = 'prices',
  PAYMENT_INTENTS_APP = 'prices/:app_id', // TODO: refactor backend side
  PAYMENT_INTENTS_ITEM = 'prices/:app_id/:price_id',
  PAYMENT_INTENTS_ESTIMATE = 'prices/:app_id/:price_id/estimate',
  PAYMENT_INTENTS_VIEWS = 'prices/:app_id/:price_id/views',

  CONTRACTS_TEMPLATES = 'contracts-templates',

  WALLETS = 'wallets',
  WALLETS_APP = 'wallets/:app_id',
  WALLETS_ITEM = 'wallets/:app_id/:wallet_id',

  TRIGGERS = 'triggers',
  TRIGGERS_APP = 'triggers/:app_id',
  TRIGGERS_ITEM = 'triggers/:app_id/:trigger_id',

  WEBHOOKS = 'webhooks',
  WEBHOOKS_APP = 'webhooks/:app_id',
  WEBHOOKS_ITEM = 'webhooks/:app_id/:webhook_id',
  WEBHOOKS_APP_EVENTS = 'webhooks/:app_id/events',

  WEBHOOKS_EVENTS = 'webhooks/:app_id/:webhook_id/events',
  WEBHOOKS_EVENTS_ITEM = 'webhooks/:app_id/:webhook_id/events/:event_id',

  LOGS = 'logs',

  LOGS_APP = 'logs/:app_id',

  MEMBERS_APP = 'members/:app_id',
  MEMBERS_ITEM = 'members/:app_id/:member_id',
  MEMBERS_INVITE = 'members/invites/:invite_token',

  WAX_ACCOUNTS = 'wax/accounts',

  SHOPPERS = 'shoppers',

  THEMES = 'themes',

  THEMES_ITEM = 'themes/:theme_id',

  THEMES_APP = 'apps/:app_id/themes',
  THEMES_APP_ITEM = 'apps/:app_id/themes/:theme_id',

  SHOPPERS_PAYMENT_METHODS = 'shoppers/payment-methods',
  SHOPPERS_REMOVE_PAYMENT_METHODS = 'shoppers/payment-methods/remove',
  SHOPPERS_WALLETS = 'shoppers/wallets',

  AUCTIONS_APP = 'auctions/:app_id',
  AUCTIONS_ITEM = 'auctions/:app_id/:auction_id',
  AUCTIONS_VIEWS = 'auctions/:app_id/:auction_id/views',
  AUCTIONS_SINGLE_ITEM = 'auctions/:auction_id',
  PAYMENT_INTENTS_AUCTION = 'prices/:app_id/auction/:auction_id',

  SUBSCRIPTION_PLANS_ITEM = 'subscription-plans/:app_id/:subscription_plan_id',
  SUBSCRIPTION_PAYMENT_ITEM = 'subscription-plans/:app_id/:subscription_plan_id/:subscription_payment_id',

  SUBSCRIPTION_PLANS_APP = 'subscription-plans/:app_id',
  SUBSCRIPTION_PLANS_VIEWS = 'subscription-plans/:app_id/:subscription_id/views',
  SUBSCRIPTION_PLANS_SINGLE_ITEM = 'subscription-plans/s/:subscription_id',
  PAYMENT_INTENTS_SUBSCRIPTION = 'prices/:app_id/subscription/:subscription_id',
}

export default API;
